import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import TopCustomer from './pages/TopCustomer/TopCustomer';
import TopTransactions from './pages/TopTransactions/TopTransactions';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/reports/top-customer" element={<TopCustomer />} />
          <Route
            path="/customer-audit-log"
            element={<TopTransactions />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
