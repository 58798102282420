import React from "react";
import { useState } from "react";
import styles from "../../styles/TopTransactions.module.scss";
import FBNStructure from "../FBNStructure/FBNStructure";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { useQuery } from "react-query";
import useScreenLock from "../../components/useScreenLock/useScreenLock";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../assets/images/fidelityLoader.gif";
import { useEffect } from "react";


const TopTransactions = () => {
  const [openModal, setOpenModal] = useState(false);
  useScreenLock(openModal);

  const year = [
    { value: 2024 },
    { value: 2023 },
    { value: 2022 },
    { value: 2021 },
    { value: 2020 },
    { value: 2019 },
    { value: 2018 },
    { value: 2017 },
    { value: 2016 },
    { value: 2015 },
  ];
  const monthData = [
    { value: "Jan", monthNumber: "01" },
    { value: "Feb", monthNumber: "02" },
    { value: "Mar", monthNumber: "03" },
    { value: "Apr", monthNumber: "04" },
    { value: "May", monthNumber: "05" },
    { value: "Jun", monthNumber: "06" },
    { value: "Jul", monthNumber: "07" },
    { value: "Aug", monthNumber: "08" },
    { value: "Sep", monthNumber: "09" },
    { value: "Oct", monthNumber: "10" },
    { value: "Nov", monthNumber: "11" },
    { value: "Dec", monthNumber: "12" },
  ];

  const formatCurrency = (value) => {
    return value?.toLocaleString("en-US", {
      style: "currency",
      currency: "Naira",
    });
  };

  const priceBodyTemplate = (product) => {
    return formatCurrency(product?.price);
  };

  const cols = [
    { field: "Transaction_date", header: "Date" },
    { field: "Lolyalty_number", header: "Loyalty Number" },
    { field: "Ref", header: "Ref" },
    { field: "Transaction_amount", header: "Incremental Income" },
    { field: "Reward", header: "Points Earned" },
  ];

  // ///////////////API CALL FOR TOP TRANSACTION/////////////

  const [selectedYear, setSelectedYear] = useState(year[0].value);
  const [membershipID, setMembershipID] = useState("");
  const [currentPageItem, setCurrentPageItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const topTransactionDate = new Date();
  const topTransactionMonth = topTransactionDate.getMonth();
  const [topTransactionMonthNumber, setTopTransactionMonthNumber] =
    useState(topTransactionMonth);

  const topTransactionMonthName = monthData[topTransactionMonthNumber];
  const [selectedMonth, setSelectedMonth] = useState(
    topTransactionMonthName?.value
  );

  const topTransactionQueryMonth = monthData.filter(
    (each) => each.value === selectedMonth
  );
  //const [tableData, setTableData] = useState([])
  const fetchTopTransaction = async (year = "", month = "") => {
    const params = {
      // api: "get_top_transactions_report",
      // year: year || undefined,
      // month: month || undefined,
      customer_id: membershipID,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_FIRSTBANK_BASEURL}/audit`,
      {
        params,
        headers: {
          "Content-Type": 'application/json',
          "dashboard-key": process.env.REACT_APP_FIRSTBANK_TOKEN,
        },
      }
    );

    //setTableData(response?.data)
    return response?.data;
  };

  const { data, isLoading, isError } = useQuery(
    [
      "Top Transaction",
      selectedYear,
      topTransactionQueryMonth,
      membershipID,
      currentPage,
    ],
    () =>
      fetchTopTransaction(
        selectedYear,
        topTransactionQueryMonth[0]?.monthNumber
      )
  );

  ///////////////// Pagination///////////////////

  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [selectedCell, setSelectedCell] = useState(null);

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
if (data?.length > 0) {
  
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  setCurrentPageItem(currentItems);
}
  }, [data, currentPage]);

  // useEffect(() => {
  //   const response = currentPageItem?.filter(
  //     (item) => item.Lolyalty_number === selectedCell?.value
  //   );

  //   if (response?.length > 0) {
  //     setOpenModal(true);
  //   }
  // }, [currentPageItem, selectedCell]);

  //console.log(data, "table data");

  return (
    <FBNStructure>
      <div className={styles.tableSection}>
        <div className={styles.topCustomer}>
          <div>
            <label htmlFor="" className={styles.membershipidLabel}>
              Enter membership ID
            </label>
            <div className={styles.searchContainer}>
              <input
                type="search"
                placeholder="Membership ID"
                value={membershipID}
                onChange={(e) => setMembershipID(e.target.value)}
                className={styles.searchInput}
              />
              {/* <button className={styles.searchButton} type="button">
                Submit
              </button> */}
            </div>
          </div>
          {/* <div className={styles.reportTop}>
            <h4>Top Incremental Income Accounts</h4>
            <p>View all the top incremental income accounts</p>
          </div> */}

          {/* <div className={styles.dropdownOutterCalendar}>
            <div className={styles.dropdownCalendar}>
              <Dropdown
                data={year}
                rangeValue={selectedYear}
                setRangeValue={setSelectedYear}
              />
              <Dropdown
                data={monthData}
                rangeValue={selectedMonth}
                setRangeValue={setSelectedMonth}
              />
            </div>
            <DownloadDropdown products={currentPageItem} cols={cols} />
          </div> */}

          <div className={styles.tableContainer}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : (
              <DataTable
                value={currentPageItem}
                sortable
                sortField="Transaction_date"
                sortOrder={-1}
                className={styles.mainTable}
                cellSelection
                selectionMode="single"
                selection={selectedCell}
                onSelectionChange={(e) => setSelectedCell(e.value)}
                // metaKeySelection={false}
              >
                <Column
                  field="date"
                  header="Date"
                  sortable
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="activity"
                  header="Action"
                  sortable
                  //className={styles.membershipIDColumn}
                  headerStyle={{ color: "#000" }}
                  style={{ width: "20%" }}
                ></Column>
                {/* <Column
                  field="Ref"
                  header="Ref"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="Transaction_amount"
                  header="Incremental Income"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="Reward"
                  header="Points Earned"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{ width: "20%" }}
                ></Column> */}
              </DataTable>
            )}
          </div>
          <div className={styles.paginationContainer}>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data?.length}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
          {/* {openModal && (
            <CustomerReportModal
              setSelectedCell={setSelectedCell}
              setOpenModal={setOpenModal}
              selectedCell={selectedCell}
              cols={cols}
            />
          )} */}
        </div>
      </div>
    </FBNStructure>
  );
};

export default TopTransactions;
