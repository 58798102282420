import axios from 'axios';
import React from 'react';
import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {ArrowUp} from '../../assets/icons';
import Dropdown from '../../components/Dropdown/Dropdown';
import styles from '../../styles/TopCustomer.module.scss';
import FBNStructure from '../FBNStructure/FBNStructure';
import {DataTable} from 'primereact/datatable';
import useScreenLock from '../../components/useScreenLock/useScreenLock';
import {Column} from 'primereact/column';
import Pagination from 'react-js-pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../assets/images/fidelityLoader.gif';
import DownloadDropdown from '../../components/Dropdown/DownloadDropdown';


const TopCustomer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('Download');
  const [filterLog, setFilterLog] = useState('Log Filter');
  const [optoutFilter, setOptoutFilter] = useState('Optout Filter')
  const [openModal, setOpenModal] = useState(false);
  const [logValue, setLogValue] = useState('')
  const [optoutFilterValue, setOptoutFilterValue] = useState('')
  const [membershipID, setMembershipID] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  useScreenLock(openModal);
  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  useEffect(()=>{
    if(filterLog === 'Log in'){
      setLogValue(1)
    }else if(filterLog === 'Not logged in'){
      setLogValue(0)
    }else{
      setLogValue('')
    }
  }, [filterLog])

  useEffect(()=>{
    if(optoutFilter === 'Active'){
      setOptoutFilterValue(1)
    }else if(optoutFilter === 'Inactive'){
      setOptoutFilterValue(0)
    }else{
      setOptoutFilterValue('')
    }
  }, [optoutFilter])

  // const handleSelectedValue = value => {
  //   setSelectedType(value);
  // };
  const year = [
    {value: 2024},
    {value: 2023},
    {value: 2022},
    {value: 2021},
    {value: 2020},
    {value: 2019},
    {value: 2018},
    {value: 2017},
    {value: 2016},
    {value: 2015},
  ];

  const filterLogin = [{value: 'Log in'}, {value: 'Not logged in'},]
  const filterOptout = [{value: 'Active'}, {value: 'Inactive'},]

  const monthData = [
    {value: 'Jan', monthNumber: '01'},
    {value: 'Feb', monthNumber: '02'},
    {value: 'Mar', monthNumber: '03'},
    {value: 'Apr', monthNumber: '04'},
    {value: 'May', monthNumber: '05'},
    {value: 'Jun', monthNumber: '06'},
    {value: 'Jul', monthNumber: '07'},
    {value: 'Aug', monthNumber: '08'},
    {value: 'Sep', monthNumber: '09'},
    {value: 'Oct', monthNumber: '10'},
    {value: 'Nov', monthNumber: '11'},
    {value: 'Dec', monthNumber: '12'},
  ];

  //////////////////////////////// Transaction Report /////////////////////////////////////////////
  const [selectedYear, setSelectedYear] = useState(year[0].value);

  const transactionReportDate = new Date();
  const transactionReportMonth = transactionReportDate.getMonth();

  const [transactionReportMonthNumber, setTransactionReportMonthNumber] =
    useState(transactionReportMonth);

  const transactionReportMonthName = monthData[transactionReportMonthNumber];

  // To get month in text
  const [selectedMonth, setSelectedMonth] = useState(
    transactionReportMonthName?.value,
  );
  // To be pass to api to get month number
  const transactionReportQueryMonth = monthData.filter(
    each => each.value === selectedMonth,
  );

  const fetchTopCustomers = async (year = '', month = '') => {
    const params = {
     // api: 'get_top_customers_report',
     
     from: fromDate,
     to: toDate,
     active: optoutFilterValue,
     membership_id: membershipID,
      year: year || undefined,
      month: month || undefined,
      loggedin: logValue
    };
    const response = await axios.get(`${process.env.REACT_APP_FIRSTBANK_BASEURL}/top_customers` , {
      params,
      headers: {
         // Accept: "*/*",
          "dashboard-key": process.env.REACT_APP_FIRSTBANK_TOKEN,
        },
    });

    return response?.data?.data;
  };

  const {data, isLoading, isError} = useQuery(
    ['Top Customer', selectedYear, transactionReportQueryMonth, logValue, membershipID,optoutFilterValue, fromDate, toDate],
    () =>
      fetchTopCustomers(
        selectedYear,
        transactionReportQueryMonth[0].monthNumber,
      ),
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const cols = [
    {field: 'loyalty_number', header: 'Loyalty Number'},
    {field: 'Total_transaction_amount', header: 'Total Incremental Income'},
    {field: 'points_earned', header: 'Points Earned'},
    {field: 'points_redeemed', header: 'Points Redeemed'},
    {field: 'curent_point_balance', header: 'Current Point Balance'},
  ];

  const [selectedCell, setSelectedCell] = useState(null);

  useEffect(() => {
    const response = currentItems?.filter(
      item => item.membership_id === selectedCell?.value,
    );

    if (response?.length > 0) {
      setOpenModal(true);
    }
  }, [selectedCell]);

  // console.log(selectedCell?.value, 'selected cell');


  return (
    <FBNStructure>
      <div className={styles.tableSection}>
        <div className={styles.topCustomer}>
          <div className={styles.reportTop}>
            <h4>All Customers</h4>
            <p>View all the top customers in the program</p>
          </div>
          <div className={styles.dropdownOutterCalendar}>
            <div className={styles.dropdownCalendar}>
              <div className={styles.inputDateAndIdContainer}>
                <label htmlFor="">From Date</label>
                <input type="date" id="fromDate" name="fromDate" value={fromDate} onChange={(e)=> setFromDate(e.target.value)} />
              </div>
              <div className={styles.inputDateAndIdContainer}>
                <label htmlFor="">To Date</label>
                <input type="date" id="toDate" name="toDate" value={toDate} onChange={(e)=> setToDate(e.target.value)} />
              </div>
              <div className={styles.inputDateAndIdContainer}>
                <label htmlFor="">Membership ID</label>
                <input type="text" name="membershipID" value={membershipID} onChange={(e)=>setMembershipID(e.target.value)} />
              </div>
              {/* <Dropdown
                data={year}
                rangeValue={selectedYear}
                setRangeValue={setSelectedYear}
              />
              <Dropdown
                data={monthData}
                rangeValue={selectedMonth}
                setRangeValue={setSelectedMonth}
              /> */}
              <div className={styles.filtersContainer}>
              <Dropdown data={filterLogin} rangeValue={filterLog} setRangeValue={setFilterLog} />

              <Dropdown data={filterOptout} rangeValue={optoutFilter} setRangeValue={setOptoutFilter} />
              </div>
          
            </div>
            <DownloadDropdown products={currentItems} cols={cols} />
          </div>

          <div className={styles.tableContainer}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : (
              <DataTable
                value={currentItems}
                sortable
                sortField="total_transaction_amount"
                sortOrder={-1}
                cellSelection
                selectionMode="single"
                selection={selectedCell}
                onSelectionChange={e => setSelectedCell(e.value)}
                metaKeySelection={false}
                // onCellSelect={onCellSelect}
                // onCellUnselect={onCellUnselect}

                className={styles.mainTable}>
                <Column
                  field="loyalty_number"
                  header="Loyalty Number"
                  headerStyle={{color: '#000'}}
                  sortable
                 // className={styles.membershipIDColumn}
                  style={{width: '20%'}}></Column>
                <Column
                  field="Total_transaction_amount"
                  header="Total Incremental Income"
                  sortable
                  style={{width: '20%'}}></Column>
                <Column
                  field="points_earned"
                  header="Points Earned"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{width: '20%'}}></Column>
                <Column
                  field="points_redeemed"
                  header="Points Redeemed"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{width: '20%'}}></Column>
                <Column
                  field="curent_point_balance"
                  header="Current Point Balance"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{width: '20%'}}></Column>
              </DataTable>
            )}
          </div>
          <div className={styles.paginationContainer}>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data?.length}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
        {/* {openModal && (
          <CustomerReportModal
            setSelectedCell={setSelectedCell}
            setOpenModal={setOpenModal}
            selectedCell={selectedCell}
            cols={cols}
          />
        )} */}
      </div>
    </FBNStructure>
  );
};

export default TopCustomer;
