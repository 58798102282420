import React from 'react';
import styles from '../../styles/Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <p className={styles.footerText}>
        Powered by Loyalty Solutions Limited, Nigeria
      </p>
      <p className={styles.footerText}>
        © Copyright 2024 Loyalty Solutions Limited. All rights reserved
      </p>
    </div>
  );
};

export default Footer;
