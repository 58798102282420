import React, { useEffect } from 'react';
import {useState, useRef} from 'react';
import {ChevronDown, ChevronUp} from '../../assets/icons';
import styles from '../../styles/Dropdown.module.scss';

const Dropdown = ({data, rangeValue, setRangeValue}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSetYear = year => {
    setRangeValue(year);
  };

  useEffect(()=>{
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [])

  

  return (
    <div>
      <div className={styles.dropdown} ref={dropdownRef}>
        <button className={styles.dropdown__toggle} onClick={toggleDropdown}  >
          <span className={styles.dropdownText}>
            {rangeValue}
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </span>
        </button>
        {isOpen && (
          <ul className={styles.dropdown__menu} >
            {data.map(each => (
              <li
                key={each.value}
                onClick={() => [handleSetYear(each.value), setIsOpen(false)]}
                className={styles.dropdown__item}>
                {each.value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
