import React from 'react';
import {useState} from 'react';
import {Times} from '../../assets/icons';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar';
import styles from '../../styles/FBNStructure.module.scss';

const FBNStructure = ({children}) => {
  const [mobileNav, setMobileNav] = useState(false);
  return (
    <div>
      <div className={styles.bigScreenNav}>
        <Sidebar />
      </div>

      {mobileNav && (
        <div className={`${styles.mobileNavStyling} ${styles.show} `}>
          <div className={styles.innerMobileNavStyling}>
            <Sidebar />
          </div>
          <div className={styles.timesIcon} onClick={() => setMobileNav(false)}>
            <Times />
          </div>
        </div>
      )}

      <div className={styles.content}>
        <Navbar setMobileNav={setMobileNav} mobileNav={mobileNav} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default FBNStructure;
