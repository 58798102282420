import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomerAuditIcon, DashboardIcon, Report } from "../assets/icons";
import styles from "../styles/Sidebar.module.scss";
import logo from "../assets/images/fb-logo.png";
import perx from "../assets/images/Perx-logo.png";

const Sidebar = () => {
  const currentURL = window.location.href;
  const linkValue = currentURL.split("/")?.length - 1;
  const currentPage = currentURL.split("/")[linkValue];

  const [activeLink, setActiveLink] = useState("/");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  useEffect(() => {
    if (currentPage === "") {
      setActiveLink("/");
    } else if (currentPage === "customer-audit-log") {
      setActiveLink("customer-audit-log");
    } else {
      setActiveLink("report");
    }
  }, [currentPage]);

  return (
    <div className={styles.Sidebar}>
      <div className={styles.sidebar}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="logo" />
        </div>
        <Link
          to="/"
          className={activeLink === "/" && styles.active}
          onClick={() => handleLinkClick("/")}
        >
          <div className={styles.innerLink}>
            <DashboardIcon activeLink={activeLink} /> Dashboard
          </div>
        </Link>
        <Link
          to="/customer-audit-log"
          className={activeLink === "customer-audit-log" && styles.active}
          onClick={() => handleLinkClick("customer-audit-log")}
        >
          <div className={styles.innerLink}>
            <CustomerAuditIcon activeLink={activeLink} /> Customer Audit Log
          </div>
        </Link>
        <div className={styles.dropdown}>
          <Link
            className={`${styles.dropdownToggle} ${
              activeLink === "report" && styles.active
            }`}
            onClick={() => {
              toggleDropdown();
              handleLinkClick("report");
            }}
          >
            <div className={styles.innerLink}>
              <Report activeLink={activeLink} /> Reports
            </div>
          </Link>

          {isOpen && (
            <ul className={styles.dropdownMenu}>
              <li className={styles.dropdownItem}>
                <Link
                  to="/reports/top-customer"
                  className={
                    currentPage === "top-customer"
                      ? styles.currentLink
                      : styles.link
                  }
                >
                  All Customers
                </Link>
              </li>
              {/* <li className={styles.dropdownItem}>
                <Link
                  to="/reports/top-transactions"
                  className={
                    currentPage === 'top-transactions'
                      ? styles.currentLink
                      : styles.link
                  }>
                  Customer Audit Log
                </Link>
              </li> */}
              {/* <li className={styles.dropdownItem}>
                <Link
                  to="/reports/top-campaigns"
                  className={
                    currentPage === 'top-campaigns'
                      ? styles.currentLink
                      : styles.link
                  }>
                  Top Campaign
                </Link>
              </li> */}
              {/* <li className={styles.dropdownItem}>
                <Link
                  to="/reports/campaign-breakdown"
                  className={
                    currentPage === 'campaign-breakdown'
                      ? styles.currentLink
                      : styles.link
                  }>
                  Campaign Breakdown
                </Link>
              </li> */}
            </ul>
          )}
        </div>
        <div className={styles.perxButtonContainer}>
          <Link to="https://greenrewardsdemo.perxclm.com/PerXCLM.php">
            <button className={styles.perxButton}>
              Go to PerX <img src={perx} alt="perx" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
